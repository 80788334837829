<template>
<div>
	<div class="header text-center">
		<NavbarMain></NavbarMain>
		<b-container class="blurb">
			<div class="display-1 mb-3">
				Keep on tinkering.
			</div>
			<!-- <div class="lead m-0 text-muted">
				Simple pricing. Less than a cup of coffee.
			</div> -->
			<div class="lead m-0 text-muted">
				Big on value. Easy on the wallet.
			</div>
		</b-container>
	</div>
	<div class="loader invisible">
		<div class="spinner"></div>
	</div>
	<div class="deck">
		<div class="plan-widget border rounded d-flex flex-column h-100 p-5">
			<div class="flex-grow-1">
				<header class="mb-5 pb-5 border-bottom">
					<div class="h5 text-muted mb-2">
						Amino Basic
					</div>
					<div class="display-2 mb-2">
						Free
					</div>
					<div class="h5">
						Completely free, always.
					</div>
				</header>
				<ul class="text-left plan-highlights list-unstyled mb-0">
					<li>
						<span class="material-icons-outlined mr-2">done</span>
						Cloud Storage
					</li>
					<li>
						<span class="material-icons-outlined mr-2">done</span>
						Stylesheet Manager
					</li>
					<li>
						<span class="material-icons-outlined mr-2">done</span>
						Best-in-Class Live CSS Editor
					</li>
					<li>
						<span class="material-icons-outlined mr-2">done</span>
						Multiple Themes
					</li>
				</ul>
			</div>
			<b-button class="mt-5" variant="primary" v-if="!isAuthenticated" to="/create-account">Get Amino Basic</b-button>
			<b-button class="mt-5" style="border-color: var(--color-500);" variant="dark" disabled v-else-if="!isPro">Current Plan</b-button>
		</div>

		<div class="plan-widget fill border rounded d-flex flex-column h-100 p-5">
			<div class="flex-grow-1">
				<header class="mb-5 pb-5 border-bottom">
					<div class="d-flex align-items-center mb-2">
						<div class="h5">
							Amino Pro
						</div>
						<div class="d-flex align-items-center ml-3" v-if="annualCheckbox && coupons.length && activeCoupon.badge">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" style="width: 24px;" class="mr-2">
								<path fill="#7F78D2" fill-rule="evenodd" d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clip-rule="evenodd" />
							</svg>
							<div class="font-weight-bold small">
								{{ activeCoupon.badge }}
							</div>
						</div>
					</div>
					<div class="d-flex align-items-center mb-2" v-if="annualCheckbox">
						<div v-if="coupons.length">
							<span class="display-2">${{activeCoupon.price}}</span>
							<span class="small text-muted">/yr</span>
						</div>
						<div v-else>
							<span class="display-2">$2.99</span>
							<span class="small text-muted">/mo (paid annually)</span>
						</div>
					</div>
					<div class="mb-2" v-else>
						<span class="display-2">$3.99</span>
						<span class="small text-muted">/mo</span>
					</div>
					<div class="d-flex">
						<div class="font-weight-bold">
							Monthly
						</div>
						<div class="ml-3 mr-1">
							<b-form-checkbox class="d-flex align-self-center plan-switch" name="check-button" switch v-model="annualCheckbox"></b-form-checkbox>
						</div>
						<div class="font-weight-bold">
							Annually
						</div>
					</div>
				</header>
				<ul class="text-left plan-highlights list-unstyled mb-0">
					<li>
						<span class="material-icons-outlined mr-2">done</span>
						Everything in Amino Basic
					</li>
					<li>
						<span class="material-icons-outlined mr-2">done</span>
						Unlimited Cloud Storage
					</li>
					<li>
						<span class="material-icons-outlined mr-2">done</span>
						Ad-Free Experience
					</li>
					<li>
						<span class="material-icons-outlined mr-2">done</span>
						Create Collections
					</li>
					<li>
						<span class="material-icons-outlined mr-2">done</span>
						Cancel Anytime
					</li>
					<li>
						<span class="material-icons-outlined mr-2">done</span>
						Support Small Business
					</li>
				</ul>
			</div>
			<b-button class="mt-5" variant="primary" disabled v-if="isAuthenticated && isPro">Current Plan</b-button>
			<b-button class="mt-5" variant="primary" v-else-if="!isAuthenticated" to="/create-account?redirect=pro%2Factivate">Get Amino Pro</b-button>
			<b-button class="mt-5" variant="primary" v-b-modal.payments-modal v-else>Get Amino Pro</b-button>
		</div>
	</div>

	<div class="testimonials">
		<flickity ref="flickity" :options="flickityOptions">
			<div class="carousel-cell">
				<figure class="testimonial-item text-center">
					<blockquote class="mb-4">
						This tool is amazing! It takes literally, minutes, to whip up out some changes to a site and get a sense of what improvements are really working.
					</blockquote>
					<img class="mb-3" src="https://lh3.googleusercontent.com/a-/AOh14Ghp8b7dGoUaoU_iaEWFhTs2fRDeYLlVWZxG6dA00w=s40-c-k">
					<figcaption class="mb-4">
						<div class="h4">
							Mike Laurel
						</div>
						<div class="text-muted">
							Chrome Web Store
						</div>
					</figcaption>
					<div class="star-rating">
						<span class="material-icons-outlined">star</span>
						<span class="material-icons-outlined">star</span>
						<span class="material-icons-outlined">star</span>
						<span class="material-icons-outlined">star</span>
						<span class="material-icons-outlined">star</span>
					</div>
				</figure>
			</div>
			<div class="carousel-cell">
				<figure class="testimonial-item text-center">
					<blockquote class="mb-4">
						I really like this extension and i use it daily! Great work guys! ✌😊
					</blockquote>
					<img class="mb-3" src="https://lh3.googleusercontent.com/a-/AOh14GiRqxRqc3eNp-Z1etUxzKiNrxAK3QVVzkUnfGQPjw=s40-c-k">
					<figcaption class="mb-4">
						<div class="h4">
							Miloš Mladenović
						</div>
						<div class="text-muted">
							Chrome Web Store
						</div>
					</figcaption>
					<div class="star-rating">
						<span class="material-icons-outlined">star</span>
						<span class="material-icons-outlined">star</span>
						<span class="material-icons-outlined">star</span>
						<span class="material-icons-outlined">star</span>
						<span class="material-icons-outlined">star</span>
					</div>
				</figure>
			</div>
			<div class="carousel-cell">
				<figure class="testimonial-item text-center">
					<blockquote class="mb-4">
						A perfect solution for my CSS habits! Certainly one of the apps I cannot live without now.
					</blockquote>
					<img class="mb-3" src="https://lh3.googleusercontent.com/a-/AOh14Gj5CbAsvgkh4Zm9Um1MlZqNMAe8QIlchGNeAt6AaA=s40-c-k">
					<figcaption class="mb-4">
						<div class="h4">
							Y Hiroto
						</div>
						<div class="text-muted">
							Chrome Web Store
						</div>
					</figcaption>
					<div class="star-rating">
						<span class="material-icons-outlined">star</span>
						<span class="material-icons-outlined">star</span>
						<span class="material-icons-outlined">star</span>
						<span class="material-icons-outlined">star</span>
						<span class="material-icons-outlined">star</span>
					</div>
				</figure>
			</div>
		</flickity>
		<div class="navigation">
			<div class="button d-flex align-items-center justify-content-center" @click="previous()">
				<div class="circle-arrow d-flex align-items-center justify-content-center">
					<span class="material-icons-outlined md-light">west</span>
				</div>
			</div>

			<div class="button d-flex align-items-center justify-content-center" @click="next()">
				<div class="circle-arrow d-flex align-items-center justify-content-center">
					<span class="material-icons-outlined md-light">east</span>
				</div>
			</div>
		</div>
	</div>

	<b-container class="faqs">
		<div class="text-center mb-4">
			<div class="h1 mb-2">FAQs</div>
			<div class="lead text-muted">
				Answers to some common questions about the product and billing.
			</div>
		</div>
		<div class="accordion" role="tablist">
			<div class="py-4 border-bottom item" v-b-toggle.accordion-1>
				<b-row>
					<b-col>
						<div class="m-0 h3">Can I use Amino for free?</div>
					</b-col>
					<b-col cols="auto align-self-center">
						<span class="material-icons-outlined d-none state-closed">add</span>
						<span class="material-icons-outlined d-none state-open">remove</span>
					</b-col>
				</b-row>
				<b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel" class="truncated text-muted mt-4">
					Yes! We have a free, ad-supported pricing plan that you can use indefinitely. This plan offers generous (but limited) cloud storage, free forever.
				</b-collapse>
			</div>
			<div class="py-4 border-bottom item" v-b-toggle.accordion-2>
				<b-row>
					<b-col>
						<div class="m-0 h3">What happens when I reach the storage limit on the free plan?</div>
					</b-col>
					<b-col cols="auto align-self-center">
						<span class="material-icons-outlined d-none state-closed">add</span>
						<span class="material-icons-outlined d-none state-open">remove</span>
					</b-col>
				</b-row>
				<b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel" class="truncated text-muted mt-4">
					Once you have reached the storage limit you will need to either pare down your user data, or upgrade to a paid plan to continue to create more stylesheets.
				</b-collapse>
			</div>
			<div class="py-4 border-bottom item" v-b-toggle.accordion-3>
				<b-row>
					<b-col>
						<div class="m-0 h3">What are my payment options?</div>
					</b-col>
					<b-col cols="auto align-self-center">
						<span class="material-icons-outlined d-none state-closed">add</span>
						<span class="material-icons-outlined d-none state-open">remove</span>
					</b-col>
				</b-row>
				<b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel" class="truncated text-muted mt-4">
					You can pay on a monthly or an annual basis. We charge per user. Debit and credit payments are accepted. Payments are powered by and secured by Stripe.
				</b-collapse>
			</div>

			<div class="py-4 border-bottom item" v-b-toggle.accordion-4>
				<b-row>
					<b-col>
						<div class="m-0 h3">How do I cancel my paid plan?</div>
					</b-col>
					<b-col cols="auto align-self-center">
						<span class="material-icons-outlined d-none state-closed">add</span>
						<span class="material-icons-outlined d-none state-open">remove</span>
					</b-col>
				</b-row>
				<b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel" class="truncated text-muted mt-4">
					If you are paying annually, you will be notified 30 days before your annual plan is set to renew. At that time, you may choose to cancel your annual plan and switch to monthly payments. You may also choose
					to cancel your monthly or annual plan altogether and downgrade to the free plan at any time.
				</b-collapse>
			</div>

			<div class="py-4 item" v-b-toggle.accordion-5>
				<b-row>
					<b-col>
						<div class="m-0 h3">But, I have more questions.</div>
					</b-col>
					<b-col cols="auto align-self-center">
						<span class="material-icons-outlined d-none state-closed">add</span>
						<span class="material-icons-outlined d-none state-open">remove</span>
					</b-col>
				</b-row>
				<b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel" class="truncated text-muted mt-4">
					We’re here to help! Please email <a href="mailto:support@aminoeditor.com">support@aminoeditor.com</a>.
				</b-collapse>
			</div>
		</div>
		<div class="mt-4 read-more">
			<router-link to="/faqs" class="d-flex align-items-center">
				See more FAQs
				<div class="arrow-button ml-3 d-flex align-items-center justify-content-center">
					<span class="material-icons-outlined md-light">east</span>
				</div>
			</router-link>
		</div>
	</b-container>
	<div class="mb-4">
		<b-modal class="payments-modal" id="payments-modal" hide-footer centered>
			<PaymentsForm :nickname="plan"></PaymentsForm>
		</b-modal>
	</div>
	<FooterMain></FooterMain>
</div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex'
import Flickity from 'vue-flickity';
const PaymentsForm = () => import('@/components/PaymentsForm');
const FooterMain = () => import('@/components/FooterMain');
const NavbarMain = () => import('@/components/NavbarMain');
export default {
	name: 'Pro',
	mixins: [ makeFindMixin({ service: 'coupons' }) ],
	data() {
		return {
			annualCheckbox: true,
			flickityOptions: {
				initialIndex: 3,
				adaptiveHeight: true,
				prevNextButtons: false,
				pageDots: false,
				wrapAround: true
			}
		};
	},
	components: {
		NavbarMain,
		PaymentsForm,
		Flickity,
		FooterMain
	},
	computed: {
		couponsParams () {
			return { query: {} };
		},
		activeCoupon () {
			return this.coupons[0];
		},
		flag() {
			return this.$route.params.flag || false;
		},
		isAuthenticated() {
			return this.$store.getters["auth/isAuthenticated"];
		},
		plan() {
			return this.annualCheckbox ? 'Annual' : 'Monthly';
		}
	},
	methods: {
		next() {
			this.$refs.flickity.next();
		},

		previous() {
			this.$refs.flickity.previous();
		}
	},
	async mounted() {
		if (this.flag === 'activate') {
			this.$bvModal.show('payments-modal');
		}
	}
}
</script>

<style lang="scss" scoped>
.container {
	max-width: 1000px;
}
.deck {
	max-width: 1000px;
	margin: auto;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 2rem;
	padding: 0 2rem;

	@media (max-width: 1000px) {
		grid-template-columns: 1fr;
		grid-row-gap: 2rem;
	}
}
.header {
	.blurb {
		padding: 6rem 2rem;
	}
}
.plan-widget {
	&.border {
		border-color: var(--color-500) !important;
	}
	header {
		border-bottom-color: var(--color-500) !important;
	}
	&.fill {
		background-color: var(--color-700);
	}
	.plan-highlights {
		li {
			.material-icons-outlined {
				font-size: 1.5rem;
				color: var(--color-primary);
			}
			&:not(:last-of-type) {
				margin-bottom: 1rem;
			}
		}
	}
	@media (max-width: 767px) {
		height: auto;
	}

}

.plan-frequency-switch {
	position: relative;
	font-size: 0.9rem;
	.interior {
		display: flex;
		justify-content: center;
	}
	.row {
		display: flex;
		justify-content: center;
		background-color: var(--color-900);
		position: relative;
		z-index: 999;
		position: relative;

	}
	&:after {
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		height: 1px;
		background-color: var(--color-800);
		content: "";
	}
}

.accordion {
	.item {
		&.border-bottom {
			border-bottom-color: var(--color-500) !important;
		}
		.state-closed {
			color: var(--color-100);
		}
		&.not-collapsed {
			.state-open {
				display: block !important;
			}
			.title {
				color: white;
			}
		}
		&.collapsed {
			.state-closed {
				display: block !important;
			}
		}
	}

	.truncated {
		max-width: 650px;
	}
}

.testimonials {
	position: relative;
	.navigation {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: calc(960px - 2rem);
		margin: auto;
		display: flex;
		justify-content: space-between;
		@media (max-width: 960px) {
			width: calc(100% - 3rem);
		}
		.button {
			border: 1px solid var(--color-500);
			width: 3rem;
			height: 3rem;
			background-color: var(--color-700);
			border-radius: 6rem;
			cursor: pointer;
			.material-icons-outlined {
				font-size: 1rem;
			}
		}
	}
}

.flickity-enabled {
	background-color: var(--color-700);
	padding: 4rem 0;
	margin-top: 4.5rem;
}

.carousel-cell {
	width: 100%;
}

.faqs {
	margin: 4.5rem auto;
}

.read-more {
	a {
		color: var(--color-100);
		&:hover {
			color: var(--color-white);
		}
	}
}

.testimonial-item {
	max-width: 640px;
	margin: auto;
	@media (max-width: 960px) {
		padding: 0 5rem;
	}
	blockquote {
		font-family: var(--global-display-font-family);
		font-weight: bold;
		font-size: 1.75rem;
		line-height: 1.2;
	}
	img {
		width: 4rem;
		height: 4rem;
		border-radius: 8rem;
	}
	.star-rating {
		display: flex;
		justify-content: center;
		.material-icons-outlined {
			font-size: 1.125rem;
			color: #FEC84B;
			&:not(:last-of-type) {
				margin-right: 0.25rem;
			}
		}
	}
}
</style>
